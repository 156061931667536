/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [

  {
    header: 'مشاريع خارجية',
    icon: 'PackageIcon',
    items: [
      {
        url: null,
        name: "الموقع",
        icon: "NavigationIcon",
        submenu: [

          {
            url: "/analytics/website",
            name: "إحصائيات الموقع",
            slug: "dashboard-ecommerce",
            permission: "get_analytics"
          },
          {
            url: "/tags/Website",
            name: "تاغات الموقع",
            slug: "dashboard-ecommerce",
            permission: "get_tags"
          },
          {
            url: "/feedback",
            name: "الرسائل",
            slug: "dashboard-ecommerce",
            permission: "get_feedbacks",
            submenu: [
              {
                url: "/website/messages",
                name: "الرسائل",
                slug: "dashboard-feedback-eessage",
                permission: 'get_feedbacks'
              },
              {
                url: "/feedback/Testimonials",
                name: "التوصيات",
                slug: "dashboard-feedback-testimonials",
                permission: 'get_feedbacks'
              }
            ]
          },
          {
            url: "/blog",
            name: "المدونات",
            slug: "dashboard-blogs",
            permission: "get_blogs",
            submenu: [
              {
                url: "/blog/Event",
                name: "الفعاليات",
                slug: "dashboard-blogs-event",
                permission: 'get_blogs'
              },
              {
                url: "/blog/News",
                name: "الاخبار",
                slug: "dashboard-blogs-news",
                permission: 'get_blogs'
              },
              {
                url: "/blog/SuccessStory",
                name: "قصص النجاح",
                slug: "dashboard-blogs-successStories",
                permission: 'get_blogs'
              },
              {
                url: "/faqs",
                name: "الاسئلة والاجوبة",
                slug: "dashboard-blogs-FAQ",
                permission: 'get_blogs'
              },
              // {
              //   url: "/blog/DonateProgram",
              //   name: "برامج التبرع",
              //   slug: "dashboard-blogs-DonateProgram",
              //   permission: 'get_blogs'
              // },
              {
                url: "/blog/UpcomingEvent",
                name: "الفعاليات القادمة",
                slug: "dashboard-blogs-UpcomingEvent",
                permission: 'get_blogs'
              },
            ]
          }
        ]
      },


      // {
      //   url: null,
      //   name: "التبرعات",
      //   tagColor: "warning",
      //   icon: "DollarSignIcon",
      //   submenu: [
      //     {
      //       url: "/donation",
      //       name: "التبرعات الواردة",
      //       slug: "dashboard-analytics",
      //       permission: "get_donations"
      //     },
      //     {
      //       url: "/donation-invites",
      //       name: "دعوات التبرع",
      //       slug: "dashboard-ecommerce",
      //       permission: "get_invitations"
      //     }
      //   ]
      // },

      {
        url: null,
        name: "كريم",
        tagColor: "warning",
        icon: "DollarSignIcon",
        submenu: [
          {
            url: "/donations/programs",
            name: "برامج التبرع",
            slug: "dashboard-donations",
            permission: "get_programs"
          },
          {
            url: "/donations/all",
            name: "التبرعات",
            slug: "dashboard-ecommerce",
            permission: "get_donations"
          },
          // {
          //   url: "/kareem/faqs",
          //   name: "الاسئلة والاجوبة",
          //   slug: "dashboard-donations-FAQ",
          //   permission: 'get_faqs'
          // },
        ]
      },
      {
        url: null,
        name: "المتجر الالكتروني",
        tagColor: "warning",
        icon: "ShoppingCartIcon",
        submenu: [
          {
            url: "/ecommerce/market",
            name: "المتاجر",
            slug: "dashboard-ecommerce",
            permission: "get_markets"
          },
          {
            url: '/ecommerce/stores',
            name: 'المحال',
            slug: 'ecommerce-stores',
            permission: "show_stores"
          },
          {
            url: '/ecommerce/categories',
            name: 'اصناف المنتجات',
            slug: 'ecommerce-categories',
            role: 'store_admin',
            permission: "get_categories"
          },
          {
            url: '/ecommerce/variant',
            name: 'الخصائص',
            slug: 'ecommerce-products',
            permission: 'get_variantKeys'
          },
          {
            url: "/tags/Ecommerce",
            name: "تاغات المتجر الالكتروني",
            slug: "dashboard-ecommerce",
            permission: "get_subProductTags"
          },
          {
            url: '/ecommerce/products',
            name: 'المنتجات',
            slug: 'ecommerce-products',
            role: 'store_admin',
            permission: "get_products"
          },
          {
            url: "/sliders",
            name: "سلايدرات",
            slug: "dashboard-ecommerce",
            permission: 'get_sliders'
          },
          {
            url: "/ecommerce/packageSizes",
            name: "التغليف",
            slug: "dashboard-ecommerce",
            permission: 'get_package_sizes'
          },
          {
            url: "/ecommerce/users",
            name: "حسابات الزبائن",
            slug: "dashboard-ecommerce",
            permission: "get_users"
          },
          {
            url: null,
            name: 'الطلبات',
            role: 'store_admin',
            tagColor: "warning",
            permission: 'get_orders',
            submenu: [
              {
                url: "/ecommerce/personal",
                name: "شخصي",
                slug: "ecommerce-orders",
                permission: "get_personalOrders"
              },
              {
                url: "/ecommerce/friend_gift",
                name: "هدية لصديق",
                slug: "ecommerce-orders",
                permission: 'get_friendGift'
              },
              {
                url: "/ecommerce/orphan_gift",
                name: "هدية ليتيم",
                slug: "ecommerce-orders",
                permission: 'get_orphanOrders'
              }
            ]
          },
        ]
      },
      {
        url: null,
        name: "الإستمارات",
        tagColor: "warning",
        icon: "FileTextIcon",
        submenu: [
          {
            url: "/employment-forms",
            name: "التوظيف",
            slug: "dashboard-analytics",
            permission: "get_forms"
          },
          {
            url: "/teaching-forms",
            name: "التدريس",
            slug: "dashboard-analytics",
            permission: "get_forms"
          },
          {
            url: "/volunteering-forms",
            name: "التطوع",
            slug: "dashboard-analytics",
            permission: "get_voluntaryForms"
          },
          {
            url: "/training-forms",
            name: "التدريب",
            slug: "dashboard-analytics",
            permission: "get_forms"
          }
        ]
      },

    ]
  },


  {
    header: 'مشاريع داخلية',
    icon: 'PackageIcon',
    items: [
      {
        url: null,
        name: "الاختبارات والمقاييس",
        tagColor: "warning",
        icon: "EditIcon",
        submenu: [
          {
            url: "/raven_configuration?type=kids",
            name: "إعدادات رافن للصغار",
            slug: "dashboard-Quiz",
            permission: 'get_raven_configuration_kids'
          },
          {
            url: "/raven_configuration?type=adults",
            name: "إعدادات رافن للراشدين",
            slug: "dashboard-Quiz",
            permission: 'get_raven_configuration_adults'
          },
          {
            url: "/raven_configuration?type=activation_code",
            name: "رمز التفعيل",
            slug: "dashboard-Quiz",
            permission: 'get_raven_activation_code'
          },
          {
            url: '/raven_results?type=Kids',
            name: 'نتائج رافن للصغار',
            slug: "dashboard-Quiz",
            permission: 'get_raven_results_kids'
          },
          {
            url: '/raven_results?type=Adults',
            name: 'نتائج رافن للراشدين',
            slug: "dashboard-Quiz",
            permission: 'get_raven_results_adults'
          }
        ]
      },
      {
        url: '/jabies',
        name: "الجوابي",
        tagColor: "warning",
        icon: "MapPinIcon",
        permission: 'get_jabi',
        submenu: []
      },
      {
        url: null,
        name: "الفعاليات",
        tagColor: "warning",
        icon: "ActivityIcon",
        submenu: [
          {
            url: "/eventsDefinition",
            name: "الحفلات",
            slug: "dashboard-event",
            permission: 'get_events_definition'
          }
        ]
      },


      {
        url: null,
        name: "قارئ الباركود",
        tagColor: "warning",
        icon: "ActivityIcon",
        submenu: [
          {
            url: "/barcode",
            name: "المشاريع",
            slug: "dashboard-barcode",
            permission: 'get_barcode_projects'
          }
        ]
      },


      {
        url: null,
        name: "الزوّار",
        tagColor: "warning",
        icon: "UsersIcon",
        submenu: [
          {
            url: "/visitors/rehabAlHabeb",
            name: "رحاب الحبيب",
            slug: "dashboard-analytics"
          },
        ]
      },
      {
        url: null,
        name: "رحاب الحبيب",
        tagColor: "warning",
        icon: "UsersIcon",
        submenu: [
          {
            url: "/rihab-alhabib/users",
            name: "المستخدمون",
            slug: "dashboard-rihab-alhabib",
            permission: 'get_rihab_users'
          },
          {
            url: "/rihab-alhabib/statistics",
            name: "الإحصائيات",
            slug: "dashboard-rihab-alhabib",
            permission: 'get_rihab_users'
          },
        ]
      },
      {
        url: null,
        name: "زادي",
        tagColor: "warning",
        icon: "BookOpenIcon",
        submenu: [
          {
            url: "/zadi/magazines",
            name: "المجلات",
            slug: "dashboard-zadi",
            permission: 'magazines_management'
          },
          {
            url: "/zadi/books",
            name: "الملخصات",
            slug: "dashboard-zadi",
            permission: 'books_management'
          },
        ]
      },
    ]
  },


  {
    header: 'عام',
    icon: 'PackageIcon',
    items: [

      {
        url: null,
        name: "إعدادات عامة",
        tagColor: "warning",
        icon: "SettingsIcon",
        submenu: [
          {
            url: "/dev-configuration",
            name: "اعدادات المطور",
            slug: "developer-configuration",
            permission: 'get_configurations'
          },
          {
            url: "/sliders",
            name: "سلايدرات",
            slug: "dashboard-ecommerce",
            permission: "get_sliders"
          },
          {
            url: "/analytics/other",
            name: "الإحصائيات العامة",
            slug: "dashboard-ecommerce",
            permission: "get_analytics"
          },
          {
            url: "/roles",
            name: "الأدوار",
            slug: "dashboard-ecommerce",
          },
          {
            url: "/groups",
            name: "المجموعات",
            slug: "dashboard-ecommerce",
            permission: "get_groups"
          },
          {
            url: "/users/dashboard",
            name: "مستخدمي لوحة التحكم",
            slug: "dashboard-ecommerce",
            permission: "get_users"
          },
          {
            url: "/districts",
            name: "المناطق",
            slug: "dashboard-ecommerce",
            permission: "show_all_districts"
          },
          {
            url: "/jobs/failed",
            name: "الرسائل المعلقة",
            slug: "dashboard-configuration",
            permission: "get_failed_messages"
          },
          {
            url: "/telegram/contacts",
            name: "جهات اتصال التلغرام",
            slug: "dashboard-configuration",
            permission: "manage_contacts"
          },
        ]
      },

    ]
  },

];
