<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown
    vs-custom-content
    vs-trigger-click
    class="cursor-pointer"
  >
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-2"
      :badge="unreadNotificationCount"
    />
    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">
          {{ unreadNotificationCount }} إشعارات جديدة
        </h3>
        <div class="vx-row mt-5 cursor-pointer">
          <div class="vx-col sm:w-1/2 w-full">
            <span
              class="flex items-start"
              @click="deleteAllNotification()"
            >
              <feather-icon
                icon="Trash2Icon"
                svg-classes="h-4 w-4"
                class="mr-2"
              />
              <span class="text-grey-light">حذف الكل</span>
            </span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <span
              class="flex items-end"
              @click="readAllNotification()"
            >
              <feather-icon
                icon="CheckSquareIcon"
                svg-classes="h-4 w-4"
                class="mr-2"
              />
              <span class="text-grey-light">جعل الكل كمقروء</span>
            </span>
          </div>
        </div>
      </div>
      <component
        :is="scrollbarTag"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items">
          <li
            v-for="ntf in unreadNotifications"
            :key="ntf.index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            :style="{ backgroundColor: ntf.read_at === null?'#EDEBEB': 'white'}"
            @click="readNotification(ntf)"
          >
            <div class=" flex items-start">
              <div class="vx-col">
                <MessageIcon :fill-color="ntf.data.color"/>
              </div>
              <div class="vx-col mx-2">
                <span class="font-medium block notification-title text-primary">{{ ntf.data.title }}</span>
                <small>{{ ntf.data.body }}</small>
              </div>
            </div>
            <div class="vx-col">
              <small class="mr-3 mt-1  whitespace-no-wrap date">{{ notificationTime(ntf.created_at) }}</small>
            </div>
            <div class="vx-col">
              <feather-icon
                @click="deleteNotification(ntf.id)"
                icon="Trash2Icon"
                svg-classes="h-6 w-6"
                class="text-primary mr-2"
              />
            </div>
          </li>
        </ul>
      </component>
      <div
        v-show="isAllDelete"
        class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer"
      >
        <span @click="deleteAllNotification()">لا يوجد إشعارات</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import toasted from "@/app/shared/utilities/toasted";
import utilities from "@/app/shared/utilities";
import {getNotificationUrl} from "../../../../../public/notification-handler";

const notificationRepo = SharedRepositoryFactory.get('notificationRepository');
export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      unreadNotifications: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      isAllDelete: false,
      isClick: true,
      isLengthChange: false,
      firebaseNotification: '',
      unreadNotificationCount: '',
      isRead: false
    };
  },
  watch: {
    isLengthChange: function () {
      if (this.isRead)
        this.unreadNotificationCount = this.unreadNotificationCount;
      else
        this.unreadNotificationCount = this.unreadNotificationCount + 1;
    }
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  created() {
    this.fetchAllNotification();
  },
  methods: {
    fetchAllNotification() {
      notificationRepo.fetchAllNotification().then((data) => {
        this.unreadNotifications = data.notifications;
        this.unreadNotificationCount = data.unreadNotificationCount;
        if (this.catchFirebaseNotification(this.unreadNotifications, this.unreadNotificationCount) !== this.unreadNotificationCount) {
          this.isLengthChange = true;
        }
      });
    },
    catchFirebaseNotification(array, count) {
      const that = this;
      this.$messaging.onMessage(function (payload) {
        let data = {
          title: payload.data.title,
          body: payload.data.body,
          color: payload.data.color,
          notification_type: payload.data.notification_type,
          order_id: payload.data.order_id,
          order_type: payload.data.order_type
        };
        let firebaseNotification = {data, read_at: null, id: payload.data.notification_id};
        array.unshift(firebaseNotification);
        that.isLengthChange = true;
        count++;
        toasted.success(payload.data.title + ' : ' + payload.data.body);
      });
      return count;
    },
    deleteNotification(NotificationId) {
      this.isClick = false;
      notificationRepo.deleteNotification(NotificationId).then((response) => {
        if (response.code === 200)
          this.fetchAllNotification();
        this.isClick = true;

      });
    },
    readNotification(notification) {
      if (this.isClick) {
        if (notification.read_at === null)
          notificationRepo.readNotification(notification.id).then((response) => {
            if (response.code === 200) {
              this.isRead = true;
              this.fetchAllNotification();
              this.isLengthChange = true;
            }
          });
        let url = getNotificationUrl(notification.data);
        this.$router.push({path: url.split('#')[1]});
        this.isRead = false;
      }
    },
    readAllNotification() {
      notificationRepo.readAllNotification().then(() => {
        this.fetchAllNotification();
      });
    },
    deleteAllNotification() {
      notificationRepo.deleteAllNotification().then(() => {
        this.fetchAllNotification();
        this.unreadNotifications.forEach(item => {
          if (item.read_at === null)
            this.isAllDelete = false;
          else
            this.isAllDelete = true;
        });
      });
    },
    notificationTime(time) {
      return utilities.elapsedTime(time);
    }

  }
};

</script>
