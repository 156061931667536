import {environment} from "@/environment";

let env = 'https://dashboard.t-tamayoz.com/';
const URL = `${environment.apiBaseURL}`;

if (URL.includes('staging')){
  env= 'https://dashboard-staging.t-tamayoz.com/';
}
export const getNotificationUrl = (notification) => {
  switch (notification.notification_type) {
    case 'ecommerce_order': {
      return `${env}#/ecommerce/orders/${notification.order_id}/order_details/${notification.order_type}`;
    }
    case 'website_message': {
      //TODo handle notification.feedback_type
      return `${env}#/website/messages`;
    }
    case 'stock_runs_out': {
      return `${env}#/ecommerce/products/${notification.productId}/sub_products/${notification.subProductId}`;
    }
  }
};
